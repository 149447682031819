import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const Caution = ({ data }) => (
  <Layout>
    <SEO title="注意事項" />
    <h2>ツキ板をもっとわかりやすく、使いやすく</h2>
    <p className="p-2">
      ElegantSelectionは「掲載画像は参考見本です」ではなく、「掲載画像は実際にプロジェクトに納めるツキ板です」として、空間を創るデザイナー、クライアントにとってわかりやすく、使いやすく提案しています
    </p>
    <h3>これまでのデザイナーの課題</h3>
    <ul>
      <li>「天然木ツキ板のため、写真と現物の色柄は異なります」と書いてある</li>
      <li>実際にプロジェクトで使うツキ板が確認できない</li>
      <li>小さいカットサンプルで完成をイメージできない</li>
      <li>ツキ板の写真と届いたサンプルが大きく違う</li>
      <li>サンプルが小さくて全体のイメージがわからない</li>
      <li>プロジェクトで使う木目や色が完成までわからない</li>
      <li>事前にもらったサンプルと完成のイメージが違う</li>
      <li>「天然木だから」という文言が多く、結局よくわからない</li>
    </ul>
    <h3>解決</h3>
    <ul>
      <li>本ウェブサイトに掲載してるツキ板が、プロジェクトで使うツキ板</li>
      <li>木目全体のイメージが事前にわかる</li>
      <li>空間デザインに合うか確認しやすい</li>
      <li>事前にボリュームを確認できるのでスペックしやすい</li>
      <li>木目のユニークさ</li>
      <li>スペックナンバー管理なので安心できる</li>
    </ul>
    <hr />
    <h2>設計事務所、デザイナーへのお願い</h2>
    <p className="p-2">
      Elegant Selectionは、プロジェクトごとのオーダーメイドの製品です。 <br />
      デザイナー、設計者との打ち合わせをおこない、ツキ板を製作しております。
      <br />
      発注をいただく施工業者様とのスペックの情報共有、サンプル確認、サイズやボリュームの情報確認など、一貫して管理いたしております。
    </p>
    <h3>スペックナンバー発行について</h3>
    <p className="p-2">
      Elegant
      Selectionのツキ板は製品ごとに数量を記載しております。数量に限りがある製品になるため、すべてプロジェクトごとにスペックナンバーで管理しています。そのため、ご検討中の数量（検討範囲やサイズ）をまずお伺いしております。
      <br /> <br />
      <span className="text-red-400 font-bold">
        図面にスペックをご記載いただく際は必ず事前にお問い合わせをお願いしております。
        <br />
        他物件とのブッキングを回避するために、発行されたスペックナンバー以外での発注は承ることができません。
      </span>
    </p>
    <hr />
    <h2>木目の変化</h2>
    <div className="md:flex">
      <div className="md:w-1/2">
        <p className="p-2">
          ツキ板は自然の産物。使用枚数によって、木目シートにはない木目の柄が変化していきます。
          <br />
          <br />
          ツキ板は一点ものでありながら、同じロットでも、同じ木目ができない、ツキ板の幅が変わる難しさがあります。
          <br />
          <br />
          使用範囲の木目をどう構成するかなど具体的に打ち合わせをおこないます。
        </p>
      </div>
      <div className="md:w-1/2">
        <Img fluid={data.support1.childImageSharp.fluid} alt="木目の変化" />
      </div>
    </div>
    <hr />
    <h2>お打ち合わせから納品までのフロー</h2>
    <p className="p-2">
      Elegant Selectionは、プロジェクトごとのオーダーメイドの製品です。 <br />
      デザイナー、設計者との打ち合わせをおこない、ツキ板を製作しております。
      <br />
      発注をいただく施工業者様とのスペックの情報共有、サンプル確認、サイズやボリュームの情報確認など、一貫して管理いたしております。
    </p>
    <div>
      <ol>
        <li className="font-bold">1.デザイナー、設計者様との打ち合わせ</li>
        <p className="p-2">
          お考えの空間イメージのパースやツキ板のイメージをお知らせください。
          ご希望の樹種を打ち合わせいたします。
        </p>
        <li className="font-bold">2.サンプル作成</li>
        <p className="p-2">
          プロジェクトの情報、ご検討中の範囲などを確認後、ツキ板の構成含め、実物のサンプル、実際に使う範囲でのCGを提出、全体のイメージを確認いただきます。
        </p>
        <li className="font-bold">3.スペックナンバーの発行</li>
        <p className="p-2">
          決定した樹種に関してスペックナンバーを発行いたします。発注をいただく施工業者様に、打ち合わせ内容を管理しますので、必ずスペックナンバーをご指示ください。
        </p>
        <li className="font-bold">4.施工業者様との打ち合わせと納入</li>
        <p className="p-2">
          デザイナー、設計者様との打ち合わせを基に、施工業者様と製品の打ち合わせ後、製作し納品いたします。
        </p>
      </ol>
    </div>
    <div className="md:w-full my-4">
      <Img
        fluid={data.support7.childImageSharp.fluid}
        alt="パネルのバリエーション"
      />
    </div>
    <div className="md:flex mb-4">
      <div className="md:w-1/2">
        <Img
          fluid={data.support5.childImageSharp.fluid}
          alt="パネルのバリエーション"
        />
      </div>
      <div className="md:w-1/2">
        <Img
          fluid={data.support6.childImageSharp.fluid}
          alt="パネルのバリエーション"
        />
      </div>
    </div>
    <hr />
    <h2>他の形状でご検討の場合</h2>
    <div className="flex mb-4">
      <div className="w-1/2">
        <p className="p-2">
          Elegant Selectionページ内の形状以外でも対応可能です。 <br />
          <a
            href="http://www.elegantwood.net/product/panels"
            target="_blank"
            rel="noreferrer"
          >
            <span className="underline">こちら</span>
          </a>
          のデザインパネルよりお選びいただき、ご連絡をお願いいたします。 <br />
          <span className="text-sm">
            ※ツキ板が練付できないデザインもございます。
          </span>
        </p>
      </div>
      <div className="w-1/2">
        <Img
          fluid={data.support3.childImageSharp.fluid}
          alt="パネルのバリエーション"
        />
      </div>
    </div>
    <hr />
    <h2>サンプルについて</h2>
    <div className="md:flex mb-4">
      <div className="md:w-1/2">
        <p className="p-2">
          プロジェクトの情報、ご検討中の範囲などを確認後、ツキ板の構成含め、実物のサンプルをご提出させていただきます。
        </p>
      </div>
      <div className="md:w-1/2">
        <Img fluid={data.support2.childImageSharp.fluid} alt="サンプル" />
      </div>
    </div>
    <hr />
    <h2>掲載のない樹種について</h2>

    <div className="md:flex mb-4">
      <div className="md:w-1/2">
        <p className="p-2">
          弊社はツキ板の企画販売会社、石井商店が提携しております。 <br />
          Elegant Selectionに掲載している樹種以外もたくさんの樹種を取り扱っております。
          <br />
          ご希望の樹種があればお気軽にお知らせください。
        </p>
      </div>
      <div className="md:w-1/2">
        <Img fluid={data.support4.childImageSharp.fluid} alt="突板の種類" />
      </div>
    </div>
    <hr />
    <h2>お問い合わせ</h2>
    <p className="p-2">
      ツキ板の木目、数量（検討範囲）、スペックナンバーに関して、ご不明な点などあればご連絡お願いいたします。
    </p>
    <div className="p-2">
      <span className="block">株式会社エレガントウッドコーポレーション</span>
      <span className="block">TEL:0944-87-3242</span>
      <a href="https://ws.formzu.net/dist/S76204586/">
        <button className="block border w-96 hover: border-gray-400 m-auto mt-8 px-16 py-4 text-center shadow-md bg-gray-400 text-white hover:text-gray-400 hover:bg-white hover:shadow-none outline-0">
          お問い合わせ
        </button>
      </a>
    </div>
  </Layout>
)
export const query = graphql`
  query {
    support1: file(relativePath: { eq: "veneer_support.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support2: file(relativePath: { eq: "sample_support.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support3: file(relativePath: { eq: "product_support.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support4: file(relativePath: { eq: "ishii_support.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support5: file(relativePath: { eq: "work1_support.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support6: file(relativePath: { eq: "work2_support.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    support7: file(relativePath: { eq: "wall_support.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Caution
